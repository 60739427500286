export const getBaseUrl = () => {
  return "/api/v1";
};
export const URLS = {
  // front
  ADMIN_LOGIN: () => `${getBaseUrl()}/front/admin/login`,
  USER_LOGIN: () => `${getBaseUrl()}/front/user/login`,
  FRONT_EVENTS: () => `${getBaseUrl()}/front/events`,
  SET_PASSWORD_ACCOUNT: () => `${getBaseUrl()}/front/accounts/set_password`,
  ADMIN_AUTH_OTP: () => `${getBaseUrl()}/front/accounts/authenticate_otp`,
  CHECK_RESET_ACCOUNT: () => `${getBaseUrl()}/front/accounts/check_reset_password`,
  RESET_PASSWORD: () => `${getBaseUrl()}/front/reset_password`,
  // admin
  FLOORS: () => `${getBaseUrl()}/admin/floors`,
  FLOOR: (id) => `${getBaseUrl()}/admin/floors/${id}`,
  ACCOUNTS: () => `${getBaseUrl()}/admin/accounts`,
  CHECK_IF_LOGIN: () => `${getBaseUrl()}/admin/accounts/check_if_login`,
  ACCOUNT: (id) => `${getBaseUrl()}/admin/accounts/${id}`,
  REGIONS: () => `${getBaseUrl()}/admin/regions`,
  CURRENT_REGIONS: () => `${getBaseUrl()}/admin/regions/current_regions`,
  LIST_ATTENDANCE: () => `${getBaseUrl()}/admin/attendances/attendance_and_guest`,
  LIST_GUEST_CUSTOMERS: () => `${getBaseUrl()}/admin/guest_customers`,
  LIST_GUEST_CUSTOMER: (id) => `${getBaseUrl()}/admin/guest_customers/${id}`,
  CHANGE_ATTENDANCE_TYPE: () => `${getBaseUrl()}/admin/attendances/change_attendance_type`,
  AREAS: () => `${getBaseUrl()}/admin/areas`,
  AREA_OPTIONS_CHART: () => `${getBaseUrl()}/admin/area_option_charts`,
  ADMIN_NEW_ANSWER_SHEETS: () => `${getBaseUrl()}/admin/answer_sheets/new`,
  ADMIN_BOOKINGS: () => `${getBaseUrl()}/admin/bookings`,
  ADMIN_BOOKING_EXPORT_CSV: () => `${getBaseUrl()}/admin/bookings/export_csv`,
  ADMIN_CALL_AWAIT_BOOKINGS: () => `${getBaseUrl()}/front/bookings/call_await_bookings`,
  ADMIN_SEARCH_BOOKINGS: () => `${getBaseUrl()}/admin/bookings/search_bookings`,
  ADMIN_BOOKING_STATUSES: () => `${getBaseUrl()}/admin/bookings/booking_statuses`,
  ADMIN_AWAIT_BOOKING_STATUSES: () => `${getBaseUrl()}/admin/bookings/await_booking_statuses`,
  ADMIN_GET_DAILY_ROUTINES: () => `${getBaseUrl()}/admin/stores/get_daily_routines`,
  ADMIN_SEND_MESSAGE_TEMPLETE: () => `${getBaseUrl()}/admin/templete_messages`,
  ADMIN_POST_DAILY_ROUTINES: () => `${getBaseUrl()}/admin/stores/post_daily_routines`,
  ADMIN_UPDATE_STATUS_BOOKING: (id) => `${getBaseUrl()}/admin/bookings/${id}/update_status`,
  ADMIN_BOOKING: (id) => `${getBaseUrl()}/admin/bookings/${id}`,
  ADMIN_CHANGE_STATUS_BOOKING_TIME: (id) => `${getBaseUrl()}/admin/bookings/${id}/change_status_booking_time`,
  ADMIN_CANCEL_BOOKING: (id) => `${getBaseUrl()}/admin/bookings/${id}/cancel_booking`,
  STORES: () => `${getBaseUrl()}/admin/stores`,
  STORES_LIST: () => `${getBaseUrl()}/admin/stores/stores_list`,
  STORES_WITH_REGION: () => `${getBaseUrl()}/admin/stores/stores_with_region`,
  STORE: (id) => `${getBaseUrl()}/admin/stores/${id}`,
  PUBLIC_STORE: (id) => `${getBaseUrl()}/admin/stores/${id}/public_store`,
  SERVICES: () => `${getBaseUrl()}/admin/services`,
  GET_SERVICES_ROOM: () => `${getBaseUrl()}/admin/services/get_services_room`,
  SERVICE: (id) => `${getBaseUrl()}/admin/services/${id}`,
  MOVE_POSITION_SERVICE: (id) => `${getBaseUrl()}/admin/services/${id}/move_position`,
  MOVE_POSITION_OPTION: (id) => `${getBaseUrl()}/admin/options/${id}/move_position`,
  OPTIONS: () => `${getBaseUrl()}/admin/options`,
  OPTION: (id) => `${getBaseUrl()}/admin/options/${id}`,
  OPTION_TYPE_RICH_MENUS: (id) => `${getBaseUrl()}/admin/option_type_rich_menus`,
  STAFF_MONTHLY_SCHEDULES: () => `${getBaseUrl()}/admin/staff_monthly_schedules`,
  STAFF_MONTHLY_SCHEDULE: (id) => `${getBaseUrl()}/admin/staff_monthly_schedules/${id}`,
  THERAPIST_MONTHLY_SCHEDULE: () => `${getBaseUrl()}/admin/staffs/therapist_monthly_schedules`,
  STAFFS: () => `${getBaseUrl()}/admin/staffs`,
  THERAPIST_STAFFS: () => `${getBaseUrl()}/admin/staffs/therapists`,
  STAFF: (id) => `${getBaseUrl()}/admin/staffs/${id}`,
  STAFF_ROLES: () => `${getBaseUrl()}/admin/staffs/role_staffs`,
  MOVE_POSITION_STAFF: (id) => `${getBaseUrl()}/admin/staffs/${id}/move_position`,
  THERAPISTS: () => `${getBaseUrl()}/admin/therapists`,
  CATEGORIES: () => `${getBaseUrl()}/admin/categories`,
  CATEGORY: (id) => `${getBaseUrl()}/admin/categories/${id}`,
  CATEGORY_TYPES: () => `${getBaseUrl()}/admin/categories/category_types`,
  QUESTION_TYPES: () => `${getBaseUrl()}/admin/questionnaires/question_types`,
  ADMIN_QUESTIONNAIRES: () => `${getBaseUrl()}/admin/questionnaires`,
  ADMIN_QUESTIONNAIRE: (id) => `${getBaseUrl()}/admin/questionnaires/${id}`,
  GET_MONTHLY_SCHEDULE_OF_STORE: (id) => `${getBaseUrl()}/admin/stores/${id}/get_monthly_schedule`,
  UPDATE_MONTHLY_SCHEDULE: (id) => `${getBaseUrl()}/admin/monthly_schedules/${id}`,
  MONTHLY_SCHEDULES: () => `${getBaseUrl()}/admin/monthly_schedules`,
  GET_ANSWER_SHEET: () => `${getBaseUrl()}/admin/answer_sheets/get_answer_sheet`,
  ADMIN_EXAMINATION_ROOMS: () => `${getBaseUrl()}/admin/examination_rooms`,
  ROOMS: () => `${getBaseUrl()}/admin/examination_rooms`,
  PREFECTURES: () => `${getBaseUrl()}/admin/prefectures`,
  RICH_MENU_GROUPS: () => `${getBaseUrl()}/admin/rich_menu_groups`,
  RICH_MENU_GROUP: (id) => `${getBaseUrl()}/admin/rich_menu_groups/${id}`,
  RICH_MENU_GROUP_DUPLICATE: (id) => `${getBaseUrl()}/admin/rich_menu_groups/${id}/duplicate`,
  TAB_TEMPLATES: () => `${getBaseUrl()}/admin/tab_templates`,
  ROOM: (id) => `${getBaseUrl()}/admin/examination_rooms/${id}`,
  MOVE_POSITION_ROOM: (id) => `${getBaseUrl()}/admin/examination_rooms/${id}/move_position`,
  CREATE_CHANNEL_MENU: (id) => `${getBaseUrl()}/admin/rich_menu_groups/${id}/create_channel_menu`,
  USER_TYPES: () => `${getBaseUrl()}/admin/rich_menu_groups/user_types`,
  //store
  STORE_SERVICES: () => `${getBaseUrl()}/store/services`,
  STORE_SERVICE: (id) => `${getBaseUrl()}/store/services/${id}`,
  STORE_STAFFS: () => `${getBaseUrl()}/store/staffs`,
  STORE_STAFF: (id) => `${getBaseUrl()}/store/staffs/${id}`,
  //user
  USER_ANSWER_SHEETS: () => `${getBaseUrl()}/user/answer_sheets`,
  USER_ANSWER_SHEET: (id) => `${getBaseUrl()}/user/answer_sheets/${id}`,
  USER_NEW_ANSWER_SHEETS: () => `${getBaseUrl()}/user/answer_sheets/new`,
  USER_BOOKING_ANSWER_SHEETS: () => `${getBaseUrl()}/user/answer_sheets/get_booking_answer_sheet`,
  USER_CATEGORIES: () => `${getBaseUrl()}/user/categories`,
  LIST_CATEGORY_BOOKING: () => `${getBaseUrl()}/user/categories/list_category_booking`,
  LIST_CATEGORY_INFORMATION: () => `${getBaseUrl()}/user/categories/list_category_information`,
  USER_SERVICES: () => `${getBaseUrl()}/user/services`,
  USER_OPTIONS: () => `${getBaseUrl()}/user/options`,
  CATEGORY_STORE_USER: (id) => `${getBaseUrl()}/user/categories/${id}`,
  FLOORS_USER: () => `${getBaseUrl()}/user/floors`,
  FLOOR_STORE_USER: (id) => `${getBaseUrl()}/user/floors/${id}/floor_stores`,
  STORE_USER: (id) => `${getBaseUrl()}/user/stores/${id}`,
  USER_SCHEDULES: () => `${getBaseUrl()}/user/schedules`,
  USER_STAFFS: () => `${getBaseUrl()}/user/staffs`,
  USER_THERAPISTS: () => `${getBaseUrl()}/user/therapists`,
  USER_CANCEL_BOOKING: (id) => `${getBaseUrl()}/user/bookings/${id}/cancel_booking`,
  USER_BOOKING_INFO: () => `${getBaseUrl()}/user/bookings/booking_info`,
  USER_BOOKINGS: () => `${getBaseUrl()}/user/bookings`,
  UPDATE_BOOKING_PHONE: () => `${getBaseUrl()}/user/bookings/update_booking_phone`,
  USER_EVENT_BOOKINGS: () => `${getBaseUrl()}/user/event_bookings`,
  USER_LIST_BOOKINGS:() => `${getBaseUrl()}/user/bookings/list_bookings`,
  COUNT_AWAIT_BOOKING:() => `${getBaseUrl()}/user/bookings/count_await_booking`,
  USER_BOOKING: (id) => `${getBaseUrl()}/user/bookings/${id}`,
  USER_SERVICE: (id) => `${getBaseUrl()}/user/services/${id}`,
  CUSTOMERS: () => `${getBaseUrl()}/user/customers`,
  UPDATE_LAST_LOGIN: () => `${getBaseUrl()}/user/customers/update_last_login`,
  ADMIN_CUSTOMERS: () => `${getBaseUrl()}/admin/customers`,
  ADMIN_CUSTOMER: (id) => `${getBaseUrl()}/admin/customers/${id}`,
  GET_CUSTOMER: () => `${getBaseUrl()}/user/customers/get_customer`,
  OUT_SYSTEM_USER: () => `${getBaseUrl()}/user/customers/out_system`,
  CHECK_CUSTOMER_SCAN_QR_CODE: () => `${getBaseUrl()}/front/customers/check_customer_scan_qr_code`,
  CUSTOMER_ANALYSIS: () => `${getBaseUrl()}/admin/customer_analysis`,
  CUSTOMER_ANALYSIS_EXPORT: () => `${getBaseUrl()}/admin/customer_analysis/export_csv`,
  ADMIN_EVENTS: () => `${getBaseUrl()}/admin/events`,
  ADMIN_EVENT_BOOKINGS: (id) => `${getBaseUrl()}/admin/events/${id}/booking_users`,
  ADMIN_EVENT_UPDATE: (id) => `${getBaseUrl()}/admin/events/${id}/update_event_day`,
  USER_EVENTS: () => `${getBaseUrl()}/user/events`,
  USER_CURRENT_REGION: () => `${getBaseUrl()}/user/users/user_current_region`,
  USER_CREATE_EVENT_BOOKING: (id) => `${getBaseUrl()}/user/events/${id}/create_event_bookig`,
  GET_API_INFORMATIONS: () => `${getBaseUrl()}/user/informations/get_api_news`,
  USER_LINK_REGIONS: () => `${getBaseUrl()}/user/link_regions`,
  //common
  DEPARTMENTS: () => `${getBaseUrl()}/common/departments`,
};
